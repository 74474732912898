import React, {Suspense, lazy, useState, useEffect} from 'react';
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import './App.css';
import Loader from './Loader';
import Rules from './footer/Rules';
import Privacy from './footer/Privacy';
import Gallery from './Page/gallery';
import CookieConsent from "react-cookie-consent";
import CloseSession from './closeSession';
const checkTokenRequest=async(url,data)=>{

const resp=await fetch(url,{
method:'POST',
body:JSON.stringify(data),
headers:{
  'Content-Type': 'application/json'
}     });
   const json = await resp.json();
    return json;
}



const Header = lazy(() => {
 

  return new Promise(resolve => {
    setTimeout(() => resolve(import("./Header")), 1000);
  });
});
const Footer = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./Footer")), 1000);
  });
});
const Page = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./Page")), 2000);
  });
});
const Avability = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./Avability")), 1000);
  });
});
const Shop = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./Shop")), 1000);
  });
});

const MyAccount = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./MyAccount")), 1000);
  });
});



function App() {
  const [isLogged, setIsLogged]=useState(null);
  const [logData, setLogData]=useState(null);
  const [closeSessionAlert,setCloseSessionAlert]=useState(0);




  useEffect(()=>{
    if(sessionStorage.getItem('token'))
    {
      const data={
        token:sessionStorage.getItem('token'),
        id:parseJwt(sessionStorage.getItem('token')).user_id,
        email:parseJwt(sessionStorage.getItem('token')).email,
    
    
    }
            const getDateUser= checkTokenRequest("https://mmevents.pl/db/checkToken.php",data);
            if (getDateUser){
       
    
                getDateUser.then(  function(result) {
                  if (result.connected===true)
                  {
                
                  setIsLogged(parseJwt(sessionStorage.getItem('token')).user_id);
                  setLogData(parseJwt(sessionStorage.getItem('token')).email);
                  }
                  else 
                  {
                    setIsLogged(null);
                    setLogData(null);
                    
                  }
    })
    }
      
    }





/*if (sessionStorage.getItem('token'))
{

setIsLogged(parseJwt(sessionStorage.getItem('token')).user_id);
setLogData(parseJwt(sessionStorage.getItem('token')).email);
}
*/



  },[]);

  useEffect(()=>{
//setTimeout(setCloseSessionAlert(1),50000)
if (isLogged){const timer=setInterval(()=>{


setCloseSessionAlert(1)

},1500000);
//1500000
return ()=>clearTimeout(timer);}
  },[isLogged]);


const changePageTitle=(title)=>
{
document.title=title;
}


function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

 

  return (
  
    <Suspense fallback={<Loader/>}>
    
    <BrowserRouter>
    <Header
    isLogged={isLogged}
    setIsLogged={setIsLogged}
    logData={logData}
    setLogData={setLogData}
    />
    <Routes>
    <Route path='products/fotolustro' element={<Shop cpt={changePageTitle} title="System rezerwacji fotolustra na imprezy - mmevents.pl" isLogged={isLogged} setIsLogged={setIsLogged} setLogData={setLogData} text={"Fotolustro"}  />}/>

    <Route path='offer' element={<Page cpt={changePageTitle} title="mmevents.pl -  Najepsze fotolustro na wynajem - Fotobudka - Szczecin" />}/>
      <Route path='avability' element={<Avability cpt={changePageTitle} title="Dostępność terminu - mmevents.pl - Fotobudki - Fotolustra "/>}/>
      <Route path='/' element={<Page cpt={changePageTitle} title="mmevents.pl -  Najepsze fotolustro na wynajem - Fotobudka - Szczecin"/>}/>
      <Route path='rules' element={<Rules cpt={changePageTitle} title="Regulamin strony - mmevents.pl - Fotobudki - Fotolustra "/>}/>
      <Route path='privacy' element={<Privacy cpt={changePageTitle} title="Polityka prywatności- mmevents.pl - Fotobudki - Fotolustra "/>}/>
      <Route path='/MyAccount'  element={<MyAccount cpt={changePageTitle} title="Moje konto - mmevents.pl - Fotobudki - Fotolustra " setIsLogged={setIsLogged} isLogged={isLogged} data={logData}  setLogData={setLogData} />}/>
      <Route path='/galery'  element={<Gallery cpt={changePageTitle} title="Galeria zdjęć - mmevents.pl" />}/>

    </Routes>
    <CookieConsent
  location="bottom"
  buttonText="Rozumiem!"
  cookieName="cookiebaner"
  style={{ background: "#ff01af" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
>
 Ta strona wykorzystuje pliki cookie. Używamy informacji w nich zawartych w celu zapewnienia maksymalnej wygody w korzystaniu z naszego serwisu. Gromadzone pliki cookie mogą wykorzystywać także współpracujące z nami firmy celem analityki serwisu oraz dopasowania reklam. Korzystając ze strony akceptujesz wykorzystywanie cookies. {" "}
 
</CookieConsent>
{(closeSessionAlert&&isLogged&&logData&&sessionStorage.getItem('token'))?<CloseSession logData={logData} isLogged={isLogged} setLogData={setLogData} setIsLogged={setIsLogged} close={setCloseSessionAlert}/>:null}

    <Footer/>
    </BrowserRouter>
    

    </Suspense>
  );
}

export default App;
